<script setup>
import { ref, nextTick, computed, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const connection = computed(() => store.state.common.sockedConnectionStatus)

let connectionDisplayStatus = ref(true)

watch(connection, (val) => {
    if (val.avaliable) {
        connectionDisplayStatus.value = false
    } else {
        connectionDisplayStatus.value = true
    }
}, { deep: true })
</script>

<template>
    <div class="loader" v-show="connectionDisplayStatus">
        <div class="loader__inner">
            {{ connection.status }}
        </div>
    </div>
</template>

<style scoped lang="scss">
    .loader {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: rgba(255, 255, 255, 1);
        top: 0;
        left: 0;
        z-index: 5;
        &__inner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            padding: 20px;
            border-radius: 20px;
            border: 1rem solid var(--border-grey-color);
            background: white;
            font-size: 32rem;
            font-weight: 500;
            color: var(--primary-color);
        }
    }
</style>
