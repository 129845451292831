<script setup>
import { ref, nextTick, computed, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const isBlocked = computed(() => store.state.common.caseBlocker)
</script>

<template>
    <div class="loader" v-show="isBlocked">
        <div class="loader__inner">
            The case is currently being used by another user.
        </div>
    </div>
</template>

<style scoped lang="scss">
    .loader {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: rgba(255, 255, 255, 0.8);
        top: 0;
        left: 0;
        z-index: 5;
        &__inner {
            position: absolute;
            padding: 20px;
            border-radius: 20px;
            border: 1rem solid var(--border-grey-color);
            background: white;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            font-size: 32rem;
            font-weight: 500;
            text-align: center;
            color: var(--primary-color);
        }
    }
</style>
